import React from 'react';
import './MyblogStyle.css';
import BlockchainImage from '../images/blockchain.jpeg';
import Navbar from '@components/navbar/navbar';

function Blockchain(props) {
    return (
        <div>
        <div className="background-blue">
        <canvas className="canvas"></canvas>
            <Navbar></Navbar>
            <div className="blog">
                <div className="blog-image">
                    <img src={BlockchainImage} alt='Blockchain'></img>
                </div>

                <div className="blog-inside">
                    <div className="blog-heading"><h2>How Blockchain Can Revolutionise Social Media And CIS</h2></div>
                    <br></br><br></br>
                    <div className="blog-content">
                        <h3>What is Blockchain?</h3>
                        <p>Blockchain, which began to emerge as a real-world tech option in 2016 and 2017, is poised to change IT in much the same way open-source software did a quarter-century ago. Blockchain is a system of recording information in a way that makes it difficult or impossible to change, hack, or cheat the system. Each block in the chain contains several transactions, and every time a new transaction occurs on the blockchain, a record of that transaction is added to every participant’s ledger. The recent hype around this relatively new technology is real because DLT, represents a new paradigm for how information is shared; tech vendors and enterprises, not surprisingly have rushed to learn how they can use the distributed ledger technology (DLT) to save time and admin costs.</p>
                        <br></br><br></br>
                        <h3>How Blockchain is going to Revolutionise Social Media and CIS?</h3>
                        <p>While using our social media account we knowingly or unknowingly diverged information about ourself, say our location, hobbies, likes, and dislikes etc. By a process called ‘Digital Profiling,’ information about individuals is gathered and analysed from their interaction with a digital data network. Our social media accounts are an example of such a network.
                        Blockchain social media gives prime importance to security and privacy of information (a feature that was lacking in traditional social media). This is probably one of the main reasons why several social media applications are already leveraging it.
                        The biggest blockchain social media influencer is Joshuwa Roomsburg. Joshuwa is a blockchain and digital marketing expert. He is the founder and CEO of ChainLeak.com.</p>
                        <br></br><br></br>
                        <h3>(a) Blockchain Social Media Revolution:<br></br>1.Anonyous Messaging:</h3>
                        <p>Blockchain is capable of transforming social media in such a way that applications can be created to support anonymous messaging for people.</p>
                        <h3>2. Decentralised Social Network:</h3>
                        <p>
                        Since blockchain decentralizes the entire system, it becomes extremely difficult for a hacker to hack into the social media networks and then into somebody’s account. As a result, there is no single point of attack.
                        </p>
                        <h3>3. Secure Currencies within a Platform:</h3>

                        <p>Using blockchain, social media can also be converted into an online market portal where people can buy or sell via secured transactions using cryptocurrency or cryptocurrency wallets. 
                        </p>
                        <h3>4. Control Over Self-Created Content:</h3>
                        <p>In traditional social media, the content we post does not usually stay under our own patent. Anybody from any part of the world has access to the content that we post on our social media profile. They can further copy our content and take credit for our creation. This has resulted in people losing out on credits that they deserved. Using blockchain-enabled social media platform, people have total control over the content that they have created and earn credits for what they have generated.
                        </p>
                        <h3>5. Verified News Feed:</h3>
                        <p>
                        A lot of times, social media has been reported to post misleading news posts. These posts often lead people into believing something that never happened. With people accepting blockchain-powered social media, the news feed that is  shown is checked for its authenticity and can therefore never be misleading.
                        </p>
                        <h3>6. Inaccessible for Google Indexing:</h3>
                        <p>As of now, all our details can be found using the indexing feature of Google. By using this technique, many companies gain access to people’s information that may breach an individual’s privacy. By accepting blockchain in social media platforms , indexing won’t be a possibility.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Blockchain;