import React from 'react';
import './Terms.css';
import Navbar from '@components/navbar/navbar';

function Terms(props) {


    return (
        
        <div className="background-blue">
        <canvas className="canvas"></canvas>
            <Navbar></Navbar>
            <section>
                <div className="terms">
                    <h2 className="common-h1">Terms Of Service</h2>
                        <p className="common-p">

                        Welcome to CIS's Terms of Service. Please read this carefully before using our services
                        or products. This is a contract between you and CIS. Please feel free to contact us if
                        you have any questions, issues or suggestions.<br></br><br></br>
                        Accepting the Terms of Service<br></br><br></br>
                        Please read these Terms of Service carefully before using the mobile applications,
                        service, and/or any content provided by CIS. (CIS corporation collectively with its
                        agents, representatives, consultants, employees, officers, and directors, "CIS", "We" or
                        "us"). By using or accessing the services, you agree to be bound all the terms and
                        conditions of this Agreement. If you don't agree to all the terms and conditions of this
                        Agreement, you shouldn't, and aren't permitted to, use the services or mobile
                        application provided by CIS. CIS's personalized Services cannot be provided and the
                        terms of this Agreement cannot be performed without CIS processing of the information
                        about you and other users. Processing of the information you share with CIS is
                        essential to the personalized services which we provide and which you expect, including
                        personalized Content (as described below) and ads, and is a necessary part of our
                        performance of the agreement we have with you.<br></br><br></br>
                        Modification to this Agreement<br></br><br></br>
                        CIS reserves the right to modify this Agreement by posting a revised Agreement on and/
                        or through the Services. Modifications will not apply retroactively. You are responsible
                        for reviewing and becoming familiar with any modifications to this Agreement.
                        We may sometimes ask you to review and to explictly agree to (or reject) a revised
                        version of this Agreement. In such cases, modifications will be effective at the time of
                        your Agreement to the modified version of this Agreement. If you do not agree at that
                        time, you are not permitted to use the services.<br></br><br></br>
                        As CIS grows and improves, we might have to make changes to these Terms of
                        Services.<br></br><br></br>
                        Accounts<br></br>
                        In order to access and use our services, you may need to register and create an
                        account (your "Account"). When you create an account with us, you guarantee that you
                        are above the age of 18, you may be required to provide your mobile number in order to
                        verify your Account by text message and that the information you provide us is
                        accurate, complete, and current at all the times. Inaccurate, incomplete, or obsolete
                        information may result in the immediate termination of your account on the App.
                        When creating an Account, don't:<br></br>
                        • Provide any false personal information to us (including without limitation
                        a false username) or create an Account for anyone other than yourself
                        without such other person's permission.<br></br>
                        • Use a username that is the name of another person with the intent to
                        impersonate that person;<br></br>
                        • Use a username that is subject to rights of another person without
                        appropriate authorization; or<br></br>
                        • Use a username that is offensive, vulgar or obscene or otherwise in bad
                        taste.<br></br><br></br>
                        You are responsible for maintaining the confidentiality of your account and password,
                        including but not limited to the restriction of access to your account. You agree to accept
                        responsibility for any and all the activities or actions that occur under your account
                        and/or password, whether your password is with our App or a third-party service. You
                        must notify us immediately upon becoming aware of any breach of security or
                        unauthorized use of your account.<br></br><br></br>
                        You can link your Account to Facebook and google accounts. You may only link your
                        own accounts. We will access and retrieve your account information from these sites on
                        your behalf (in some cases, by using your username and password) to make certain
                        information or services available to you through the service. By linking your other
                        accounts, you expressly authorize us to access your account information from those
                        third party accounts, on your behalf as your agent and permit us to access, use and, in
                        accomplish the foregoing.<br></br><br></br>
                        Some of the Basic Terms of Service<br></br>
                        • You must be 18 years old to use the service.<br></br>
                        • You must not create or submit unwanted email, comments, likes or
                        other forms of commercial or harassing communications ("spam") to any
                        CIS user.<br></br>
                        • You must not create accounts with the service through unauthorized
                        means, including but not limited to, by using an automated device, script
                        bot, spider, crawler or scraper.<br></br>
                        • You agree that you will not solicit, collect or use the login credentials of
                        other CIS users.<br></br>
                        • You may not post violent, nude, partially nude, discriminatory, unlawful,
                        infringing, hateful, pornographic or sexually suggestive photos or other
                        content via the service.<br></br>
                        • You are responsible for any activity that occurs through your account
                        and you agree you will not sell, transfer, license or assign your account,
                        username, or any account rights. With the exception of people or
                        businesses that are expressly authorized to create accounts on behalf
                        of their employees or clients. CIS prohibits the creation of and you
                        agree that you will not create an account for anyone other than yourself.
                        You also represent that all information you provide or provided to CIS
                        upon registration and at all other times will be true, accurate, current
                        and complete and you agree to update your information as necessary to
                        maintain its truth and accuracy.<br></br>
                        • You are solely responsible for your conduct and any data, text, files,
                        information, usernames, images, graphics, photos, profiles, audio and
                        video clips, sounds, musical works, works of authorship, applications,
                        links and other content or materials (collectively, "Content") that you
                        submit, post or display on or via the Service.<br></br>
                        • You must not defame, stalk, bully, abuse, harass, threaten, impersonate
                        or intimidate people or entities and you must not post private or
                        confidential information via the service, including, without limitation, your
                        or any other person's credit card information, social security or alternate
                        national identity numbers, non-public phone numbers or non-public
                        email addresses.<br></br>
                        • You must not interfere or disrupt the Service or servers or network
                        connected to the service, including by transmitting any worms, viruses,
                        spyware, malware or any other code of a destructive or disruptive
                        nature. You may not inject content or code or otherwise alter or interfere
                        with the way any CIS page is rendered or displayed in user's browser or
                        device.<br></br>
                        • You must not use domain names or web URLs in your username
                        without prior written consent from CIS.<br></br>
                        • You may not use the Service for any illegal or unauthorized purpose.
                        You agree to comply with laws, rules and regulation (for example,
                        federal, state, local and provincial) applicable to your use of the Service
                        and your content defined including but not limited to, copyright laws.
                        Violations of these Terms of Service may, in CIS sole discretion, result in termination of
                        your CIS account. You understand and agree that CIS cannot and will not responsible
                        for the Content posted in the service and you use the service at your own risk. If you
                        violate the letter or spirit of these Terms of Service, or otherwise create risk or possible
                        legal exposure for CIS, we can stop providing all or part of the Service to you.<br></br>
                        Access<br></br><br></br>
                        In order to access and use of the features of the service, you acknowledge and agree
                        that you will have to provide CIS with your mobile phone number. You expressly
                        acknowledge and agree that in order to provide the Service, CIS may periodically
                        access your contact list and/or address book on your mobile device to find and keep
                        track of mobile phone numbers of other users of the Service. When providing your
                        phone number and/or E-mail ID, you must provide accurate and complete information.
                        You hereby give express consent to CIS to access your contact list and/or address book
                        for mobile phone numbers in order to provide and use the service. We collect names,
                        addresses, mobile phone numbers or E-mail ID's. You are solely responsible for the
                        information you provided and status messages that you submit on your profile that are
                        displayed on the CIS service.<br></br><br></br>
                        Use of Communication Services<br></br><br></br>
                        This app may contain different areas like normal, private and scheduler, calendars, feed
                        section, and/or other message or communication facilities designed to enable you to
                        communicate with the public at large or with a group (collectively, "communication
                        services"). You agree to use the communication services to send post, send and
                        receive messages and material that are proper and related to the particular
                        communication service.<br></br><br></br>
                        By way of example, and not as a limitation, you agree that when using a communication
                        service, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the
                        legal rights (such as rights of privacy and publicity) of others; publish, post, upload,
                        distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene,
                        indecent or unlawful topic, name, material or information; upload files that contain
                        software or other material protected by intellectual property laws (or by rights of privacy
                        of publicity) unless you own or control the rights thereof to have received all necessary
                        consents, upload files that contain viruses, corrupted files, or any other similar software
                        or programs that may damage the operation of another's device; conduct or forward
                        surveys, contests, pyramid schemes or chain letters; download any file posted by
                        another user of a communication service that you know, or reasonably should know,
                        cannot be legally distributed in such manner; falsify or delete any author attributions,
                        legal or other proper notices or proprietary designations or labels of the origin or source
                        of software or other material contained in a file that is uploaded, restrict or inhibit any
                        other user from using and enjoying the communication services; violate any code of
                        conduct or other guidelines which may be applicable for any particular communication
                        service; harvest or otherwise collect information about others, including e-mail
                        addresses without their consent; violate any applicable laws or regulations.
                        CIS has no obligation to monitor the communication services. However, CIS reserves
                        the right to review materials posted to a communication service and to remove any
                        materials in its sole discretion. CIS reserves the right to terminate your access to any or
                        all of the communication services at any time without notice for any reason whatsoever.
                        CIS reserves the right at all the times to disclose any information as necessary to satisfy
                        any applicable law, regulation, legal process or government request, or to edit, refuse to
                        post or to remove any information or materials, in whole or in part, in CIS sole
                        discretion.<br></br><br></br>
                        Always use caution when giving out any personally identifying information about
                        yourself or others in any communication service. CIS does not control or endorse the
                        content, messages or information found in any communication service and, therefore,
                        CIS specially disclaims any liability with regard to the communication services and any
                        actions resulting from your participation in any communication service. Managers and
                        hosts are not authorized CIS spokesperson, and their views do not necessarily reflect
                        those of CIS.<br></br><br></br>
                        Materials uploaded to a communication service may be subject to posted limitations on
                        usage, reproduction and/or dissemination. You are responsible for adhering to such
                        limitations if you upload the materials.<br></br><br></br>
                        Entire Agreement<br></br><br></br>
                        This Agreement together with CIS's privacy policy set forth the entire Agreement
                        between you and CIS.<br></br><br></br>
                        Copyright Policy<br></br><br></br>
                        CIS own the intellectual property rights to any and all protectable components of the
                        Service, including but not limited to the name of the Service, artwork, end-user interface
                        elements contained within the service, and many of the individual features. You may not
                        copy, modify, adapt, reproduce, distribute, reverse engineer, decompile, or disassemble any respect of
                        the service which CIS own.
                        CIS claims no intellectual property rights over the content you upload or provide the
                        service.<br></br><br></br>
                        Privacy policy<br></br><br></br>
                        Please refer to our Privacy policy for information on how we collect, use and disclose
                        information from our users. The Privacy policy is incorporated by reference into these
                        terms.<br></br><br></br>
                        Links To Other Websites<br></br><br></br>
                        Our App contains links to third party websites or services that are not owned or
                        controlled by CIS.<br></br>
                        CIS has no control over and assumes no responsibility for the content, privacy policies,
                        or practices of any third party websites or services. We do not warrant the offerings of
                        any of these entities/individuals or their websites.<br></br>
                        You acknowledge and agree that CIS shall not be responsible or liable, directly or
                        indirectly, for any damage or loss caused or alleged to be caused by or in connection
                        with use of or reliance on any such content, goods or services available on or through
                        any such third party websites or services.<br></br>
                        We strongly advice you to read the terms and conditions and privacy policies of any
                        third party websites or services that you visit.<br></br>
                        Privacy policy and user data<br></br><br></br>
                        You agree to CIS data policy, including the collection, use, processing, and sharing of
                        your information as described in our Privacy Policy, as well as the transfer and
                        processing of your information where we have or use facilities, service providers, or
                        partners, regardless of where you use our Services. You acknowledge that the laws,
                        regulations, and standards of the country in which your information is stored or
                        processed may be different from those of your own country. CIS doesn't not claim the
                        ownership of the information that you submit for CIS account. You shall have the
                        authority and rights to provider such information that you submit for your account.
                        Limitation of Liability<br></br>
                        The Service provider DISCLAIMS ALL THE LIABILITY, whether based in contract, Tort
                        (including negligence), Strict Liability or Otherwise, And Does not Accept any Liability
                        for any Loss or Damage (Direct or Indirect, Punitive, Actual, Consequential, Incidental,
                        Special, Exemplary, or Otherwise) resulting from any use of, or Inability to use, the
                        Service or any other site, APP or Service or the Material Information, Software,
                        Facilities, Services, or other content on the Service or any other site, APP or Service,
                        Regardless of the basis upon which Liability is claimed, without Limitation, you assume
                        the entire cost of all necessary Servicing, Repair or correction in the event of any such
                        Loss or Damage Arising therein. If Applicable Law does not Allow or Any part of the
                        above Limitation of Liability to apply to you, the Limitations will apply to you only to the
                        Extent Permitted by Applicable Law.
                        Warranty Disclaimer<br></br>
                        You agree that your use of CIS service shall be at your sole risk. To the fullest extent
                        permitted by law, CIS, its officers, directors, employees, and agents disclaim all
                        warranties, express or implied, In connection with the service and your use thereof, CIS
                        makes no warranties or representations about the accuracy or completeness of this
                        services content and assumes no liability or responsibility for any (1) Errors, mistakes,
                        or inaccuracies of content, (2) personal injury or property damage, of any nature
                        whatsoever, resulting from your access to and use of service, (3) Any unauthorized
                        access to or use of our servers and/or any and all personal information and/or financial
                        information stored therein, (4) Any interruption or cessation of transmission to or from
                        our service, (4) Any bugs, viruses, Trojan horses, or the like which may be transmitted
                        to or through our service through the actions of any third party, and/or (5) Any errors or
                        omissions in any content posted, emailed, transmitted, or otherwise made available via
                        the CIS service. CIS does not warrant, endorse, guarantee, or assume responsibility for
                        any product or service advertised or offered by a third party through the CIS service or
                        any hyperlinked website, CIS will not be responsible for monitoring any transaction
                        between you and third-party providers of products or services. As with the purchase of a
                        product or service through any medium or in any environment, you should use your best
                        judgement and exercise caution where appropriate.<br></br><br></br>
                        Indemnification<br></br><br></br>
                        You agree to defend, indemnify, and hold CIS its affiliates, subsidiaries, parent
                        companies and employees harmless from and against all liabilities, damages, losses,
                        and expenses of any kind (including reasonable legal fees and costs) resulting from any
                        violation by you of this Agreement or asserted by any third party due to or arising out of
                        your use or conduct with respect of the Application.<br></br><br></br>
                        Terms and Termination<br></br><br></br>
                        This Agreement shall remain in effect until terminated by you or CIS, in its sole
                        discretion, at any time and for any or no reason, suspend or terminate this Agreement
                        with or without prior notice.<br></br><br></br>
                        This Agreement will terminate immediately, without prior notice from CIS, in the event
                        that you fail to comply with any provision of this Agreement. You may also terminate this
                        Agreement by deleting the Application and all copies thereof from your mobile device.
                        CIS reserves the right to terminate or deactivate your account if any violation of this
                        Agreement, privacy policy or any rules and, or any breach or illegal activities are
                        anticipated. Once your CIS account has been terminated, your account will be removed
                        from our systems.<br></br><br></br>
                        Ownership<br></br><br></br>
                        CIS or its licensors retain ownership of all intellectual property rights in and to the
                        Application, including copies, improvements, and modifications thereof. Your right to
                        use the Application is limited to those expressly granted by this Agreement. No other
                        rights with respect of the Application or any related intellectual property rights are
                        granted or implied.<br></br><br></br>
                        Trademark and Copyrights<br></br><br></br>
                        Our services and materials incorporated by Service provider on our Services
                        ("Material") are protected by copyrights, patents, trade secrets or other proprietary rights
                        ("Copyrights"). Some of the characters, logos, or other images incorporated by Service
                        provider in our services are also protected as reregistered or unregistered trademarks,
                        trade names, and/or service marks owned by the Service providers or its licensors own
                        the title, copyright, and other intellectual property rights in the Material and Service, and
                        by using our Services, you do not acquire any ownership rights in Service or Materials
                        contained therein. Service Provider respects the intellectual property rights of others
                        and asks users of our Services to do the same.<br></br><br></br>
                        Contact Information<br></br><br></br>
                        Please free to contact us if you have any questions, issues or problems about our
                        Terms of Service. You may contact us by emailing us at <a href="#">info@cisapp.co.</a><br></br><br></br>
                        Team<br></br>
                        CIS
                    </p>
                </div>
            </section>
        </div>
    );
}

export default Terms;