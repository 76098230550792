import React, { Component } from 'react'
import './technology.scss'
import BlockchainImg from '../images/blockchainImg.png'
import AIImg from '../images/aiImg.png'
import Heading from '@components/headings/heading';

class Technology extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.text = this.props.text;
    this.changeClassName = this.changeClassName.bind(this);
    this.tech = "tech-animation";
  }

  componentDidMount() {
    this.component = document.getElementById(this.tech);
    window.addEventListener("scroll", this.changeClassName);
}

changeClassName() {
  if (window.pageYOffset > this.component.offsetTop - window.innerHeight * 3 / 4) {
      var screen = document.getElementById(this.tech + "5");
      screen.className = screen.className.replace("hide","") +" screen-animate-AI" ;
      var screen = document.getElementById(this.tech + "6");
      screen.className = screen.className.replace("hide","") +" screen-animate-blockchain" ;
      var screen = document.getElementById(this.tech + "phoneAI");
      screen.className = screen.className.replace("hide","") +" screen-animate-AI" ;
      var screen = document.getElementById(this.tech + "phoneblockchain");
      screen.className = screen.className.replace("hide","") +" screen-animate-blockchain" ;
      window.removeEventListener("scroll", this.changeClassName);
      
  }
}
  

  render() {
    return (
      <div className="technology background-blue" id={this.tech}>
        <canvas className="canvas"></canvas>
        <div className="technology-inside">
          <h1 className="common-h1 technology-heading">{this.text.heading}</h1>
          <div className="technology-contents">
            <div className="technology-content">
              <img
                src={AIImg}
                alt="AI"
                className="technology-leftContent desktop hide" id ={this.tech + "5"}
              />
              <div className="technology-text">
                <h1 className="common-h1">{this.text.points[0].heading}</h1>

                <img src={AIImg} alt="AI" className="phone hide" id ={this.tech + "phoneAI"} />
                <Heading className="common-p" text={this.text.points[0].description} type="SDisc">
                </Heading>
              </div>
            </div>

            <div className="technology-content">
              <div className="technology-text technology-leftContent">
                <h1 className="common-h1">{this.text.points[1].heading}</h1>
                <img src={BlockchainImg} alt="Blockchain" className="phone" id ={this.tech +"phoneblockchain"} />
                <Heading className="common-p" text={this.text.points[1].description} type="SDisc">
                </Heading>
                <div class="techpoints"> 
                  <Heading className="common-p" text={this.text.points[1].points[0]} type="SDisc">
                </Heading>
                  <Heading className="common-p" text={this.text.points[1].points[1]} type="SDisc">
                </Heading>
                  <Heading className="common-p" text={this.text.points[1].points[2]} type="SDisc">
                </Heading>
                
                
                  </div>
               
               
                
              </div>
              <img src={BlockchainImg} alt="Blockchain" className="desktop hide " id ={this.tech + "6"} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Technology