class RM {
    static instance;
    constructor() {
    }

    static getInstance() {
        if (RM.instance) {
            return RM.instance;
        }
        RM.instance = new RM();
        return RM.instance;
    }

    get urls() {
        return require('@config/urls.json');
    }
}

export default RM;