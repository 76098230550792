import React, { Component } from 'react'
import './security.scss'
import Navbar from '@components/navbar/navbar'
import ForInvestorsImg from '../images/forInvestorsImg.png'
import GooglePlayBadge from '@assets/google-play-badge.png';

class Security extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className="security">
        <div >
          {/* <img src={ForInvestorsImg} alt="CiSApp Banner" className="forinvestors-banner"/> */}
          <div className="security-inside">
        
            <div className="security-content">
              <p className="text-theme">
               {this.props.text.description}
              </p>
            </div>
          </div>
        </div>
        <div className="security-app-buttons">
          <a href="https://play.google.com/store/apps/details?id=com.cisapp.co"><img src={GooglePlayBadge} alt="Google play Store Button" className="gettingStartedgooglePlayStoreButton" /></a>
          {/* <a><img src={GooglePlayBadge} alt="Google play Store Button" className="gettingStartedAppStoreButton" /></a> */}
        </div>
      </div>
    )
  }
}

export default Security
