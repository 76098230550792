import React, { Component } from 'react'
import './dataProtection.scss'
import Heading from '@components/headings/heading';
import CommonFunctions from '@functions/commonFunctions';


class DataProtection extends Component {
  constructor(props) {
    super(props)
    this.state = {};
    this.text = this.props.text;
  }
  render() {
    return (
      <div className="dataProtection ">
        <div className="dataProtection-inside">
        <Heading className="heading-theme createrprotection-heading" type="MHeading" text={this.text.heading}></Heading> 
        <Heading className="heading-theme createrprotection-sub_heading" type="CSHeading" text={this.text.sub_heading}></Heading> 
        <Heading className="text-theme  creatorProtection-disc" type="MDisc" text={this.text.description}></Heading> 
        <div>
          <Heading className="text-theme" type="SHDisc" text={this.text.sub_headings[0]} color="#8B6CD8"></Heading> 
          <div class="creatorProtection-points"> 
          <Heading className="text-theme" type="Point" text={this.text.sub_headings[0].points} color="#8B6CD8">
            </Heading> 
</div>
          <Heading className="text-theme creatorProtection-sub_headings" type="SHDisc" text={ this.text.sub_headings[1]} color="#8B6CD8"></Heading> 
          <Heading className="text-theme" type="SHDisc" text={this.text.sub_headings[2]} color="#8B6CD8"></Heading> 
      </div>
      </div>
      </div>
    )
  }
}

export default DataProtection
