
import { Component } from 'react';
import './referral.scss';
import Heading from '@components/headings/heading';
import config from '@config/config.json';
import Email from '@assets/email.png';
import AppStoreImg from '@assets/appStoreImg.png';
import PlayStoreImg from '@assets/playStoreImg.png';
import ReferralSucceed from '@assets/referralSuccessful.png';
import CommonFunctions from '@functions/commonFunctions';
import rm from '@rm';
import Apiresponse from '@functions/api';

class ReferralPage extends Component {
    constructor(props) {
        super(props);
        this.text = config.referral;
        this.success = false;
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        var social = queryParams.get('social');;
        var promoCode = queryParams.get('promoCode');
        Apiresponse.cameToReferralPage(promoCode, social).then((response) => {})
    }

    onClickButton() {
        var fullName = document.getElementById(this.text.form.inputListLabel[0]);
        var phoneNumber = document.getElementById(this.text.form.inputListLabel[1]);
        var email = document.getElementById(this.text.form.inputListLabel[2]);
        var send = true;

        if (fullName.value.length < 3) {
            fullName.className += ' error';
            fullName.placeholder = "Please enter your name atleast 3 characters";
            send = false;
        }else {
            fullName.className = "";
            fullName.placeholder = this.text.form.inputList[0];
        }

        if (phoneNumber.value.length < 10) {
            phoneNumber.className += ' error';
            phoneNumber.placeholder = "Please enter 10 digit phone number";
            send = false;
        }
        else {
            phoneNumber.className = "";
            phoneNumber.placeholder = this.text.form.inputList[1];
        }

        var validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.value.match(validRegex)) {
            email.className = "";
            email.placeholder = this.text.form.inputList[2];
        } else {
            email.className += ' error';
            email.placeholder = "Enter a valid email address";
            send = false;
        }

        this.setState(curentState => {
                return {
                    loading: true,
            }});
        
        if (send === true) {
            setTimeout(() => {
                this.setState(currentState => {
                        return {
                            loading: false,
                        }
                    });
            }, 3000);
            Apiresponse.sendDataToReferal(fullName.value, email.value, phoneNumber.value).then((res) => {
                console.log(res);
                if (res.data.success === true) {
                    this.success = true;
                    this.setState(currentState => {
                        return {
                            loading: false,
                        }
                    });
                }
                else {
                    this.setState(currentState => {
                        return {
                            loading: false,
                        }
                    });
                    window.alert("Something went wrong");
                }
            }).catch((error) => {
                window.alert("Network is down");
             });
        }
        else {
            this.setState(currentState => {
                    return {
                        loading: false,
                    }
                });
            window.alert("Please enter correct details");
        }

    }

    render() {
        if (true) {
            return referral(this.text.success);
        }

        return (
            <div className="referral background-blue">
                <canvas className="canvas"></canvas>
                <div className="referral-inside">
                    <div className="referral-content">
                        <h1 className="common-h1 referral-heading">{this.text.content.heading}</h1>
                        <p className="common-h1 referral-description">{this.text.content.description}</p>
                        <div className="common-h1 referral-email">
                            <p><img src={Email} alt="email.png" />info@cisapp.co</p>
                        </div>
                        <p className="common-h1 referral-getApp">
                            GET THE APP
                        </p>
                        <div className="referral-app-links">
                            <img src={PlayStoreImg} alt="PlayStore Img" />
                            <img src={AppStoreImg} alt="AppStore Img" />
                        </div>
                    </div>

                    <div className="referral-form">
                        <div className="referral-form-inside">
                            <h3>{this.text.form.heading}</h3>
                            <div className="referral-form-input">
                                {this.text.form.inputList.map((item, index) => {
                                    return <div>
                                        <p className="referral-label">{item}</p>
                                        <input type="text" placeholder={item} id={this.text.form.inputListLabel[index]}/>
                                    </div>
                                })}

                            </div>
                            <center><button onClick={this.onClickButton.bind(this)} className="referral-form-submit">{this.text.form.submit}</button></center>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function referral(props) {
    return (
        <div className="referral-success  background-blue">

            <canvas className="canvas"></canvas>
            <center>
            <div className="referral-success-inside">
                <img className="referral-tick" src={ReferralSucceed} alt="Tick" />
                <h3 className="common-h1 ">{CommonFunctions.textToHTML(props.heading)}</h3>
                <p className="common-h1">GET THE APP</p>
                <div className="referral-app-links">
                    <a href={rm.instance.urls.playStoreLink}> <img src={PlayStoreImg} alt="PlayStore Img" /></a>
                    <a href={rm.instance.urls.appStoreLink}> <img src={AppStoreImg} alt="AppStore Img" /></a>
                </div>
            </div>
            </center>
        </div>
    );
}

export default ReferralPage;