import React from 'react'
import './Logo.css'
import {Component} from 'react'

class Logo extends Component{


    render() {
    return (
        <div className='logo-anime' >
             <div className='C' id='C'>
                 <svg width="283" height="283" viewBox="0 0 283 283" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="141.421" width="200" height="200" rx="25" transform="rotate(-45 0 141.421)" fill="#1D2C5F"/>
                    <path d="M164.669 158.741C163.728 171.746 158.914 181.983 150.226 189.454C141.593 196.925 130.193 200.66 116.026 200.66C100.532 200.66 88.3294 195.458 79.4199 185.055C70.5658 174.596 66.1387 160.263 66.1387 142.057V134.669C66.1387 123.048 68.1862 112.81 72.2812 103.956C76.3763 95.1019 82.2145 88.3229 89.7959 83.6191C97.4326 78.86 106.287 76.4805 116.358 76.4805C130.304 76.4805 141.537 80.2158 150.06 87.6865C158.582 95.1572 163.507 105.644 164.835 119.146H139.933C139.324 111.344 137.138 105.699 133.375 102.213C129.667 98.6712 123.995 96.9004 116.358 96.9004C108.058 96.9004 101.832 99.8887 97.6816 105.865C93.5866 111.786 91.4837 121 91.373 133.507V142.638C91.373 155.698 93.3376 165.243 97.2666 171.275C101.251 177.307 107.504 180.323 116.026 180.323C123.718 180.323 129.446 178.58 133.209 175.094C137.027 171.552 139.213 166.101 139.767 158.741H164.669Z"/>
                </svg>
            </div>
            <div className='I' id='I' >
                <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="11" y="0" width="103" height="124">
                <path d="M18.6725 80.0854C8.9094 70.3223 8.90941 54.4932 18.6725 44.7301L62.4088 0.993774L106.161 44.7457C115.924 54.5088 115.924 70.3379 106.161 80.101L62.4244 123.837L18.6725 80.0854Z" fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                <rect x="53" y="10.2249" width="14" height="14" transform="rotate(-45 53 10.2249)" fill="white"/>
                <rect x="53" y="35" width="19" height="89" fill="white"/>
                </g>
                </svg>
            </div>
                <div className='S' id='S'>
                    <svg width="283" height="283" viewBox="0 0 283 283" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="141.421" width="200" height="200" rx="25" transform="rotate(-45 0 141.421)" fill="#3696D2" fill-opacity="0.5"/>
                        <path d="M192.883 166.712C192.883 162.009 191.223 158.412 187.903 155.921C184.583 153.376 178.606 150.72 169.973 147.953C161.34 145.13 154.506 142.363 149.47 139.652C135.746 132.236 128.884 122.248 128.884 109.686C128.884 103.156 130.71 97.3455 134.363 92.2544C138.07 87.1079 143.355 83.0958 150.217 80.2182C157.135 77.3406 164.882 75.9018 173.459 75.9018C182.092 75.9018 189.784 77.479 196.536 80.6333C203.287 83.7322 208.516 88.1316 212.224 93.8315C215.987 99.5314 217.869 106.006 217.869 113.255H192.966C192.966 107.721 191.223 103.433 187.737 100.389C184.25 97.2902 179.353 95.7407 173.044 95.7407C166.957 95.7407 162.226 97.0411 158.85 99.6421C155.474 102.188 153.787 105.563 153.787 109.769C153.787 113.698 155.751 116.991 159.68 119.647C163.665 122.303 169.503 124.793 177.195 127.118C191.361 131.379 201.682 136.664 208.157 142.972C214.631 149.281 217.869 157.139 217.869 166.546C217.869 177.005 213.912 185.223 205.999 191.2C198.085 197.121 187.432 200.082 174.041 200.082C164.744 200.082 156.277 198.394 148.64 195.018C141.003 191.587 135.165 186.911 131.125 180.99C127.141 175.068 125.149 168.207 125.149 160.404H150.134C150.134 173.74 158.103 180.409 174.041 180.409C179.962 180.409 184.583 179.219 187.903 176.839C191.223 174.404 192.883 171.029 192.883 166.712Z"/>
                </svg>
            </div>
        </div>
    )
}
}

export default Logo
