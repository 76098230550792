// import Route from '@screens/route';
import { Markup } from 'interweave';

class CommonFunctions {
    static setTheme(theme) {
        if(theme === "LIGHT" || theme === "DARK") {
            window.sessionStorage.setItem('theme', theme);
        } else {
            window.sessionStorage.setItem('theme', "LIGHT");
        }
        window.location.reload(false);
    }

    static getTheme() {
        return "LIGHT";
        // console.log("gotchaaaaaaa")
        if (window.sessionStorage.getItem('theme') === null || !(window.sessionStorage.getItem('theme') === "LIGHT" || window.sessionStorage.getItem('theme') === "DARK")) {
            const date = new Date();
            console.log(date.getHours());
            console.log("gotcha")
            if ((17 > date.getHours() && date.getHours() >= 7)) {
                return "LIGHT";
            } else {
                return "DARK";
            }
        }
        return window.sessionStorage.getItem('theme');
    }

    static checkValidEmail(email) {
        var validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.match(validRegex)) {
            return true;
        }
        return false;
    }

    static textToHTML(text) {
        return <Markup content={text} />
    }

    static getSessionStorage(label) {
        return window.sessionStorage.getItem(label);
    }

    static setSessionStorage(label, value) {
        window.sessionStorage.setItem(label, value);
    }

    static getLocalStorage(label) {
        return localStorage.getItem(label);
    }

    static setLocalStorage(label, value) {
        localStorage.getItem(label, value);
    }

    static randomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
    
}

export default CommonFunctions;