import React, { Component } from 'react';
import './investorFeatures.scss';
import Heading from '@components/headings/heading';


class InvestorFeatures extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.text = this.props.text;
    }
    render() {
        return (
            <div className="investorFeatures">
                <div className="investorFeatures-inside">
                    
                    <Heading className="heading-theme" type="MHeading" text={this.text.heading}></Heading> 
                    
                    <Heading className="text-theme" type="Point" text={this.text.points}></Heading> 
       
                </div>
            </div>
        );
    }
}

export default InvestorFeatures;