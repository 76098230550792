import axios from "axios";
import CommonFunctions from '@functions/commonFunctions';

class Apiresponse {
    static url = 'https://api.cisapp.co/cisappwebsiteapi';
    static cisappApi = 'https://papi.cisapp.co';

    static async contactUs(name, email, phone, message) {
        let data = {
            name: name,
            email: email,
            phone: phone,
            message: message,
            type: 'contactus'
        };
        let response = await axios.post(Apiresponse.url, data).catch((error) => {
            return false;
        });
        return response;

    }

    static async subscribe(email) {
        let data = {
            email: email,
            type: 'subscribe'
        };
        let response = await axios.post(Apiresponse.url, data).catch((error) => {
            return false;
        });
        return response;
    }

    static async register(email, phone) {
        let data = {
            email: email,
            phone: phone,
            type: 'register'
        };
        let response = await axios.post(Apiresponse.url, data).catch((error) => {
            return false;
        });
        return response;
    }

    static async cameToReferralPage(promoCode, social) {
        if(CommonFunctions.getSessionStorage("referralCode") !== null) {
            console.log('return');
            return;
        }
        let data = {
            promoCode: promoCode,
            social: social
        };
        let response = await axios.post(Apiresponse.cisappApi + "/c/reference/record", data).catch((error) => {
            return false;
        });
        if(response.data.success === true) {
            CommonFunctions.setSessionStorage("referralCode", response.data.data.code);
        }
        return response;
    }

    static async sendDataToReferal(name, email, phoneNo) {
        let data = {
            name: name,
            email: email,
            mobileNo: phoneNo,
            code: CommonFunctions.getSessionStorage("referralCode")
        };
        let response = await axios.post(Apiresponse.cisappApi + "/c/reference/record", data).catch((error) => {
            return false;
        });
        return response;
    }

}

export default Apiresponse;