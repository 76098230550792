import React from 'react';
import './MyblogStyle.css';
import FacebookImage from '../images/facebook.jpeg';
import Navbar from '@components/navbar/navbar';

function Facebooksued(props) {

    return (
        <div>
            
        <div className="background-blue">
        <canvas className="canvas"></canvas>
            <Navbar></Navbar>
            <div className="blog">
                <div className="blog-image">
                    <img src={FacebookImage} alt='Blockchain'></img>
                </div>

                <div className="blog-inside">
                    <div className="blog-heading"><h2>Facebook Sued to Monopolize</h2></div>
                    <br></br><br></br>
                    <div className="blog-content">
                        <h3>Facebook's multi-year course of unlawful conduct:</h3>
                        <p> The US Federal government and 46 states, as well as the territories of Guam and the District of Columbia, have filed parallel antitrust lawsuits against Facebook, accusing the social media platform of anti-competitive behaviour by misusing its market power to create a monopoly and suppressing its smaller challengers. For almost a decade, Facebook used its monopoly power to crush smaller rivals and smother out the competition, all at the expense of its everyday users, as a result, Facebook got sued for defamation. Soon after the Federal Trade Commission (FTC) and 46 state attorney-generals on Wednesday sued the California-headquartered company, Facebook's shares dropped significantly. "Personal social networking is central to the lives of millions of Americans," said Ian Conner, Director of the FTC's Bureau of Competition. "Facebook's actions to entrench and maintain its monopoly deny consumers the benefits of competition. We aim to roll back Facebook's anticompetitive conduct and restore competition so that innovation and free competition can thrive."</p>
                        <br></br><br></br>
                            <h3>Facebook Sued for Illegal Monopolization:</h3><br></br><br></br>
                            <h3>What does FTC's Lawsuit Say?</h3>
                            <p>The Federal Trade Commission sued Facebook for the monopoly on 9th December 2020, claiming that the Social Media Giant is illegally maintaining its social networking monopoly for over a decade. Following a lengthy investigation (FTC on Facebook investigation) in cooperation with a coalition of the attorneys general of 46 states, the District of Columbia, and Guam, the complaint alleges that Facebook has engaged in a systematic strategy—including its 2012 acquisition of up-and-coming competitor Instagram, its 2014 acquisition of the mobile messaging app WhatsApp, and the enforcement of anti-competitive conditions on software developers—to eradicate threats to its monopoly. This course of conduct of Facebook abuse competition leaves consumers with few options for personal social networking and deny advertisers of the benefits of competition.</p>
                            <br></br>
                            <br></br>
                            <h3>What does FTC want?</h3><p>The Federal Facebook from Trade Commission is looking for a permanent order in federal court that could restrict implementing anticompetitive conditions on software developers; and require Facebook to seek prior approval for future mergers and acquisitions and if FTC wins then Facebook will be forced to sell Instagram and WhatsApp, two social media apps appealing to the younger users and are crucial in driving Facebook's growth.</p>
                            <br></br><br />
                            <h3>Facebook sued for a data breach:</h3><p>Information about the privacy violation was disclosed in 2018 by Christopher Wylie. Facebook is being sued privacy (failing to protect users' data) in the Cambridge Analytica breach. The scandal involved harvested Facebook data of 87 million people being used for advertising during elections.</p>
                            <br /><br />
                            <h3>Facebook Lawsuit 2020:</h3><p>According to the Facebook settlement claim form, the company had gathered and stored the personal data of users without any prior consent. As a result, a good number of Illinois Facebook users will get money from Facebook’s settlement. Along with this Google has been hit with the third antitrust lawsuit accusing it of abusing its dominance in advertising and overcharging publishers.</p>
                            <br></br><br></br>
                            <h3>Facebook's Take on the Whole Situation:</h3>
                            <p>"Today, we are sending a clear and strong message to Facebook and every other company that any efforts to stifle competition, hurt small business, reduce innovation and creativity, [and] cut privacy protections will be met with the full force of our offices," James said.
                            <br></br>"People and small businesses don't choose to use Facebook's free services and advertising because they have to, they use them because our apps and services deliver the most value," Jennifer Newstead, the company's vice president and general counsel, said in a statement.
                            <br></br>Facebook is a Tweet said it is looking into the lawsuits, but disparaged them, saying, "The government now wants a do-over with no regard for the impact that precedent would have on the broader business community."
                            <br></br>According to Facebook, "Those hard challenges are best solved by updating the rules of the Internet."
                            <br></br>"We bought them a long time ago, we invested, we grew them, and now they're really big," Sandberg said. She reiterated that the acquisitions were cleared by the government and said that unwinding them now could create an "a really big chilling problem for American business."</p>
                            <br></br><br />
                            <h3>What can happen next?</h3>
                            <p>The suit will likely to be argued upon for years and it's far from a prior closure that Facebook will have to split up. But if the FTC is at the end successful, how would Facebook go about separating itself from Instagram and WhatsApp?
The task wouldn't be easy. Instagram and WhatsApp have grown immensely since they were bought, and have become progressively reliant on their parent's technical infrastructure. In 2019, CEO Mark Zuckerberg as per reports prioritized an initiative to merge the arrangements that underlie Facebook's core social network with those of Messenger, Instagram, and WhatsApp. This move would give Zuckerberg more control over the different scopes of his company, keep users engaged within the Facebook environment, and make it harder to shatter these components apart. A digital media and marketing professor at Carnegie Mellon University. "It would be unfortunate if the court said, 'You have to divest yourself, and every single piece of data element that you collected on WhatsApp users or Instagram users now needs to be disambiguated,' " Lightman says. "That would be incredibly expensive for an entity to do. It'd be incredibly hard and disadvantageous for Facebook. I don't see that as a stipulation, but you never know." An alternative to such a wholesale split, Slaiman says, "would be to strike a deal in which Facebook and its spinoffs are independent entities that have access to some of the same data".</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Facebooksued;